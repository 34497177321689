<template>
  <div :class="inlineMode == 'true'? 'inlineMode':'modifyEmail'">
    <el-card class="modifyEmailForm" shadow="never">
      <div class="title" v-if="inlineMode=='false'">{{ $t('modifyEmail.modifyEmail') }}</div>
      <div class="text" v-if="inlineMode=='false'" >
        {{ $t('modifyEmail.mETip') }}
      </div>
      <div class="original" v-if="inlineMode=='false'">{{ $t('modifyEmail.originalEmail') }}</div>
      <el-form
        ref="modifyEmailForm"
        :model="modifyEmailForm"
        :rules="modifyEmailRules"
      >
        <el-form-item prop="password" :label="$t('personnalInfo.email')" >
          <el-input
            v-model="modifyEmailForm.password"
            :placeholder="$t('modifyEmail.psw')"
            show-password
          ></el-input>
          <span class="color1f" v-if="inlineMode=='true'" @click="submit">
              {{ $t('personnalInfo.save') }}
            </span>
        </el-form-item>
        <el-form-item prop="newEmail" label="   ">
          <el-input
            v-model="modifyEmailForm.newEmail"
            :placeholder="$t('modifyEmail.newEmailAddress')"
          ></el-input>
        </el-form-item>
      </el-form>
      <el-button type="primary" v-if="inlineMode=='false'" class="sure" @click="submit">{{
        $t('submit')
      }}</el-button>
    </el-card>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { modifyEmail, queryUserInfo } from '@/api/app'

export default {
  name: 'ModifyEmail',
  props: {
    inlineMode: {
      type : String,
      default: "false"
    }
  },
  data() {
    var validateEmail = (rule, value, callback) => {
      if (value === '') {
        callback(new Error(this.$t('modifyEmail.emailIsCorrectTip')))
        this.modifyData = false
      } else {
        if (value !== '') {
          var reg = /^([A-Za-z0-9_\-.])+@([A-Za-z0-9_\-.])+\.([A-Za-z]{2,4})$/
          if (!reg.test(value)) {
            // callback(new Error('Please fill in valid email address'))
            callback(new Error(this.$t('modifyEmail.emailIsValidTip')))
            this.modifyData = false
          } else {
            this.modifyData = true
          }
        }
        callback()
      }
    }
    return {
      originEmail: '',
      modifyData: true,
      modifyEmailForm: {
        password: '',
        newEmail: '',
      },
      modifyEmailRules: {
        password: {
          required: true,
          message: this.$t('modifyEmail.psdRequire'),
          trigger: 'blur',
        },
        newEmail: {
          required: true,
          validator: validateEmail,
          trigger: 'blur',
        },
      },
    }
  },
  computed: {
    ...mapState('account', ['loginInfo']),
  },
  mounted() {
    queryUserInfo(this.loginInfo.id).then((res) => {
      this.originEmail = res.data.result.rows[0].email
    })
  },
  methods: {
    submit() {
      this.$refs.modifyEmailForm.validate((vaild) => {
        if (vaild) {
          let m = {
            userId: this.loginInfo.id,
            password: this.modifyEmailForm.password,
            email: this.modifyEmailForm.newEmail,
          }
          modifyEmail(m)
            .then(({ data }) => {
              if (data.code == 0) {
                this.$message({
                  message: this.$t('modifyEmail.mailEditSuccess'),
                  type: 'success',
                })
                this.$router.replace('/login')
              }
            })
            .catch(() => {})
        }
      })
      // if (
      //   this.modifyData &&
      //   this.modifyEmailForm.password !== '' &&
      //   this.modifyEmailForm.newEmail !== ''
      // ) {

      // }
    },
  },
}
</script>

<style lang="scss" scoped>
.inlineMode {
  .el-card {
    border-width: 0;

    .el-form-item{
      margin-left: -28px;

    }
  }
}

.color1f {
  color: #1f7ff5;
  float: right;
  cursor: pointer;
}

.modifyEmail {
  position: absolute;
  width: 100%;
  height: 100%;
  background: url('../../assets/images/emailmodifrbg.jpg') no-repeat center
    center / 100% auto;
  margin: 0;
  .modifyEmailForm {
    height: 403px;
    width: 610px;
    position: absolute;
    top: 55%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 10000;
    box-sizing: border-box;
    .title {
      color: #383038;
      width: 100%;
      font-size: 24px;
      font-weight: 400;
      text-align: center;
    }
    .text,
    .original {
      color: #082143;
      font-size: 14px;
      text-align: center;
      width: 600px;
      margin: 0 auto 10px;
      line-height: 24px;
    }
    .text {
      text-align: left;
    }
    .original {
      margin: 0 auto;
      width: 300px;
      color: #596680;
    }
    ::v-deep.el-form-item__content {
      width: 400px;
      margin: 0 auto;
    }

    .sure {
      width: 400px;
      margin-left: 86px;
      margin-top: 10px;
    }
    .back {
      display: block;
      text-decoration: none;
      width: 100px;
      margin: 10px auto;
    }
  }
}
</style>
